import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { animated, useSpring, config } from 'react-spring';
import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';

const Frame = styled(animated.div)`
  border: none;
  padding: 0;
  cursor: help; 
  aspect-ratio: 1.5;
  overflow: hidden;

  & :active {
    outline: none;
  }
  & :focus {
    outline: 3px dashed #666;
  }  

  & video {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProjectThumb = ({ project, mp4, webm, image }) => {
  const sizes = useSiteMetadataSizes();
  const videoRef = useRef(null);
  const [isHover, setIsHover] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { opacity } = useSpring({
    opacity: isPlaying ? 1 : 0,
    config: config.gentle,
  });

  useEffect(() => {
    if (!videoRef.current) return () => {};
    if (!isHover) {
      const timer = setTimeout(() => {
        videoRef.current.pause();
        setIsPlaying(false);
      }, 2500);
      return () => clearTimeout(timer);
    }
    // videoRef.current.currentTime = 0;
    const promise = videoRef.current.play();
    if (promise !== undefined) {
      promise.then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        throw new Error(error);
      });
    }
    return () => {};
  }, [isHover]);

  return (
    <Frame
      sizes={sizes}
      tabIndex="0"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <GatsbyImage style={{ pointerEvents: 'none' }} image={image} alt={project} />
      { mp4 !== '' && webm !== '' ? (
        <animated.video
          ref={videoRef}
          style={{ opacity, position: 'absolute', left: 0 }}
          loop
          muted
          playsInline
        >
          <source src={`${webm}`} type="video/webm" />
          <source src={`${mp4}`} type="video/mp4" />
        </animated.video>
      ) : null }
    </Frame>
  );
};

ProjectThumb.propTypes = {
  children: PropTypes.node,
  mp4: PropTypes.string.isRequired,
  webm: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
};

ProjectThumb.defaultProps = {
  children: null,
  isHighlighted: false,
};

export default ProjectThumb;
