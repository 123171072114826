import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { useSpring, animated } from 'react-spring';

import Meta from '../components/meta';
import Layout from '../components/layout';
import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';

import { ExternalLinkUnderline } from '../components/externalLink';
import ProjectGrid from '../components/projectGrid';
import ProjectTags from '../components/projectTags';
import AnimatingText from '../components/animatingText';
import CustomUnderline from '../components/customUnderline';

const Container = styled.div`
  margin: 2rem auto;
  max-width: ${({ sizes }) => sizes.desktop - 50}px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { margin: 2rem 3rem; }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { margin: 2rem 2rem; }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { margin: 2rem 1rem; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Header = styled.div`
  display: flex;
  column-gap: 5rem;
  row-gap: 1rem;
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { 
    flex-direction: column;
  }
`;

const Title = styled.div`
  max-width: 44%;
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { 
    max-width: 100%;
  }
`;

const Subtitle = styled.div`
  flex-shrink = 0;
`;

const Index = ({ data }) => {
  const { projectData, projectTags } = data;
  const [openProjects, setOpenProjects] = useState(projectData.edges.map((edge, i) => i));
  const sizes = useSiteMetadataSizes();
  const topRef = useRef(null);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (toOpenProjects.length > 0) {
  //       setOpenProjects(toOpenProjects);
  //       setToOpenProjects([]);
  //     }
  //   }, 700);
  //   return () => clearTimeout(timeout);
  // }, [toOpenProjects]);

  return (
    <Layout>
      <Meta
        title="Home"
        keywords={['creative technologist', 'Bryan Ma']}
      />
      <div style={{ width: '100%', height: 1 }} ref={topRef} />
      <Container sizes={sizes}>
        <Header sizes={sizes}>
          <Title sizes={sizes}>
            <h3>
              <span>
                {`${'Bryan Ma is a creative technologist and software developer'}`}
              </span>
              <AnimatingText>
                {`${' designing and building interactive systems and real-time media for emerging platforms and physical spaces.'}`}
              </AnimatingText>
            </h3>
          </Title>
          <Subtitle>
            <h3>
              {'✉️ '}
              <ExternalLinkUnderline href="mailto:hello@bryan.ma">
                <span>hello@bryan.ma</span>
              </ExternalLinkUnderline>
              {' for more info'}
            </h3>
          </Subtitle>
        </Header>
        <ProjectGrid projectData={projectData.edges} openProjects={openProjects} />
        <CustomUnderline>
          <button
            type="button"
            onClick={() => {
              if (topRef.current) {
                topRef.current.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            <h3>Top</h3>
          </button>
        </CustomUnderline>

        {/* <animated.div style={spring}> */}
        {/* <ProjectTags
          onTagClick={({tagName, catName}) => {
            // TODO: enable this:
            // if (tagName === previousTagName) return;
            let toAdd = [];
            if (tagName === 'all') {
              toAdd = projectData.edges.map((edge, i) => i);
            } else if (tagName !== '') {
              projectData.edges.forEach((edge, i) => {
                if (edge.node.frontmatter.hasOwnProperty(catName)
                  && edge.node.frontmatter[catName] !== null) {
                  if (edge.node.frontmatter[catName].includes(tagName)) {
                    toAdd.push(i);
                  }
                }
              });
            }
            if (openProjects.length > 0) {
              setOpenProjects([]);
              setToOpenProjects(toAdd);
            } else {
              setOpenProjects(toAdd);
            }
          }}
          tags={{
            domains: projectTags.domains,
            tools: projectTags.tools,
            clients: projectTags.clients,
          }}
          sizes={sizes}
        /> */}
        {/* </animated.div> */}
      </Container>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }).isRequired,
};

export default Index;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    projectData: allMarkdownRemark(filter: {frontmatter: {is_published: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          frontmatter {
            title
            date
            type
            is_published
            caption
            header
            domains
            tools
            clients
            links
            linksText
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1.5
                  placeholder: BLURRED
                )
              }
            }
            video {
              childVideoFfmpeg {
                webm: transcode(codec: "libvpx-vp9", maxWidth: 600, maxHeight: 400, fileExtension: "webm", outputOptions: ["-crf 40", "-b:v 0"]) {
                  width
                  src
                  presentationMaxWidth
                  presentationMaxHeight
                  originalName
                  height
                  aspectRatio
                }
                mp4: transcode(codec: "libx264", maxWidth: 600, maxHeight: 400, fileExtension: "mp4", options: [["-profile:v", "main"], ["-pix_fmt", "yuv420p"]], outputOptions: ["-movflags faststart", "-crf 30"]) {
                  width
                  src
                  presentationMaxWidth
                  presentationMaxHeight
                  originalName
                  height
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
    projectTags: projectTags {
      domains
      clients
      tools
    }    
  }
`;
