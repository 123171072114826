import React, { useState, useEffect } from 'react';
import { useSpring, useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import ProjectCell from './projectCell';

import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px 30px;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const ProjectGrid = ({ projectData, openProjects }) => {
  const [current, setCurrent] = useState(-1);
  const sizes = useSiteMetadataSizes();

  const projectTransitionList = useTransition(openProjects, {
    initial: { transform: 'translate3d(0,0,0)', opacity: 0 },
    from: { transform: 'translate3d(0,0,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,0,0)', opacity: 0 },
    // trail: 50,
    //delay: 100,
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      const videos = document.querySelectorAll('video');
      if (videos.length === 0) return;
      if (document.hidden) {
        videos.forEach((video) => {
          if (!video.paused) {
            video.pause();
          }
        });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      <Grid sizes={sizes}>
        { projectTransitionList((style, index, i) => {
          const { title, caption, video, image, links } = projectData[index].node.frontmatter;
          const mp4 = video ? video.childVideoFfmpeg.mp4.src : '';
          const webm = video ? video.childVideoFfmpeg.webm.src : '';
          const img = image ? image.childImageSharp.gatsbyImageData : '';
          const link = links ? links[0] : '';
          return (
            <ProjectCell
              key={index.key}
              style={style}
              projectTitle={title}
              projectLink={link}
              caption={caption}
              mp4={mp4}
              webm={webm}
              image={img}
              onClick={() => { setCurrent(current === i ? -1 : i); }}
              isHighlighted={current === i || current === -1}
            />
          );
        }) }
      </Grid>
    </>
  );
};

export default ProjectGrid;
