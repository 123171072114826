import React, { useState, useEffect } from 'react';
import PropTypes, { node } from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import AnimatingText from './animatingText';
import CustomUnderline from './customUnderline';

const Container = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  line-height: 1.7rem;
  font-size: 16pt;
  font-weight: 200;
  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { margin-top: 2rem; margin-bottom: 2rem; line-height: 1.6rem; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { margin-top: 1rem; margin-bottom: 1rem; line-height: 1.5rem; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { line-height: 1.2rem; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { line-height: 1.0rem; }
`;

const SelectTag = styled.button`
  font-size: 16pt;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { font-size: 14pt; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { font-size: 12pt; }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { font-size: 10pt; }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { font-size: 9pt; }

  font-family: 'Rubik';
  color: ${({ selected }) => (selected ? '#fff' : '#aaa')};
  background-color: ${({ selected }) => (selected ? '#222' : '')};
  padding: 0.3rem;
  border: none;
  ${'' /* padding: ${({ selected }) => (selected ? '0.29rem 0.05rem' : '0.05rem')}; */}


  &:hover {
    background-color: #222;
    color: #FFF;
    cursor: crosshair; 
    transition: 0.2s ease-out;
  }
`;

const ProjectTags = ({ onTagClick, tags, sizes }) => {
  const [currentTag, setCurrentTag] = useState({ tagName: '', catName: '' });

  useEffect(() => {
    onTagClick(currentTag);
  }, [currentTag]);

  // const toProject = () => {
  //   let project = '';
  //   if (currentTag === '') {
  //     const allTags = Object.keys(tagLinks);
  //     const randTag = allTags[Math.floor(Math.random() * allTags.length)];
  //     project = tagLinks[randTag][Math.floor(Math.random() * tagLinks[randTag].length)];
  //   } else {
  //     project = tagLinks[currentTag][Math.floor(Math.random() * tagLinks[currentTag].length)];
  //   }
  //   navigate(`/projects/${project}/`);
  // };

  return (
    <Container sizes={sizes}>
      <div>
        <SelectTag
          sizes={sizes}
          selected={currentTag.tagName === 'all'}
          onClick={() => setCurrentTag({ tagName: 'all', catName: '' })}
        >
          all
        </SelectTag>
        <span style={{ color: '#aaa' }}> - </span>
        {Object.keys(tags).map((catName, i) => (
          <span key={catName}>
            <span>
              {tags[catName].map(((tagName, j) => (
                <span key={tagName}>
                  <SelectTag
                    sizes={sizes}
                    selected={tagName === currentTag.tagName}
                    onClick={() => setCurrentTag({ tagName, catName })}
                  >
                    {tagName}
                  </SelectTag>
                  {j === tags[catName].length - 1 ? null : <span style={{ color: '#aaa' }}> - </span>}
                </span>
              )))}
            </span>
            { i < 2 ? <span style={{ color: '#aaa' }}> - </span> : null }
          </span>
        ))}
      </div>

      {/* { renderLink ? (
        <div>
          <CustomUnderline
            onClick={toProject}
            onKeyPress={toProject}
            role="button"
          >
            <h3>
              See all
            </h3>
            <h3>
              <AnimatingText>
                {getLinkCopy(currentCategory, currentTag)}
              </AnimatingText>
            </h3>
          </CustomUnderline>
        </div>
      ) : null } */}
    </Container>
  );
};

export default ProjectTags;
