import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const dir = {
  INCR: 0,
  DECR: 1,
};

const AnimatingText = (({
  children,
  delay,
  incrTime,
  decrTime,
  shouldIncrement,
  shouldDecrement,
  rendersHidden,
}) => {
  const [idx, setIdx] = useState(children.length);
  const [displayStr, setDisplayStr] = useState(children);
  const [direction, setDirection] = useState(dir.INCR);
  const [isInital, setIsInitial] = useState(true);

  useEffect(() => {
    if (!isInital) {
      setDirection(dir.DECR);
    } else {
      setIsInitial(false);
    }
  }, [children]);

  // useEffect(() => {
  //   console.log('changed displayStr');
  //   setDirection(dir.INCR);
  // }, [displayStr]);

  useEffect(() => {
    if (!isInital) {
      if (direction === dir.INCR) {
        setIdx(idx + 1);
      } else if (direction === dir.DECR) {
        if (idx === 0) {
          setDirection(dir.INCR);
        } else {
          setIdx(idx - 1);
        }
      }
    }
  }, [direction]);

  useEffect(() => {
    let timeout = null;
    if (direction === dir.INCR) {
      // console.log('idx is higher than last stored idx', idx, lastIdx);
      if (idx < displayStr.length) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          const newIdx = idx + 2;
          setIdx(Math.min(newIdx, displayStr.length));
        }, incrTime);
      }
    } else if (direction === dir.DECR) {
      // console.log('idx is lower than last stored idx', idx, lastIdx);
      clearTimeout(timeout);
      if (idx > 0) {
        timeout = setTimeout(() => {
          const newIdx = idx - 4;
          setIdx(Math.max(newIdx, 0));
        }, decrTime);
      } else if (idx === 0) {
        // console.log('start counting back up now with', children, idx);
        setDisplayStr(children);
        setDirection(dir.INCR);
      }
    }
    return () => clearTimeout(timeout);
  }, [idx]);

  return (
    <span>
      <span>
        { displayStr ? displayStr.slice(0, idx) : null }
      </span>
      { rendersHidden ? (
        <span style={{ opacity: 0 }}>
          { displayStr ? displayStr.slice(idx, displayStr.length) : null}
        </span>
      ) : null }
    </span>
  );
});

AnimatingText.propTypes = {
  children: PropTypes.string.isRequired,
  delay: PropTypes.number,
  incrTime: PropTypes.number,
  decrTime: PropTypes.number,
  shouldIncrement: PropTypes.bool,
  shouldDecrement: PropTypes.bool,
  rendersHidden: PropTypes.bool,
};

AnimatingText.defaultProps = {
  delay: 500,
  incrTime: 30,
  decrTime: 20,
  shouldIncrement: true,
  shouldDecrement: true,
  rendersHidden: true,
};

export default AnimatingText;
