import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useSpring, animated } from 'react-spring';

import ProjectThumb from './projectThumb';
import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';
import { ExternalLink } from './externalLink';

const Container = styled(animated.div)`
  flex-grow: 1;
  flex-basis: 20%;
  max-width: 50%;
  margin: auto;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { flex-basis: 20%; }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { flex-basis: 30%; }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { flex-basis: 40%; }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { flex-basis: auto; max-width: 100%; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const ProjectCaption = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0.7rem 0 0 0;
  color: ${({ isHover }) => (isHover ? '#333' : '#999AAA')};
  transition: color 0.3s ease-in-out;
`;

const trans = (x, y, s) => `perspective(700px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / (rect.height / 10),
  (x - rect.left - rect.width / 2) / (rect.width / 10),
  1,
];

const ProjectCell = ({
  projectTitle,
  projectLink,
  caption,
  mp4,
  webm,
  image,
  onClick,
  isHighlighted,
  style,
}) => {
  const sizes = useSiteMetadataSizes();
  const [xys, setXys] = useState([0, 0, 1]);
  const [isHover, setIsHover] = useState(false);
  const ref = useRef(null);
  const spring = useSpring({
    xys,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      clamp: false,
      precision: 0.01,
      velocity: 0,
      easing: (t) => t,
    },
  });

  return (
    <Container sizes={sizes} ref={ref} style={{ ...style }}>
      <animated.div
        style={{ transform: spring.xys.to(trans), zIndex: 1 }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onMouseMove={(e) => {
          const rect = ref.current.getBoundingClientRect();
          setXys(calc(e.clientX, e.clientY, rect));
        }}
      >
        <ExternalLink href={projectLink}>
        {/* <Link to={`/projects/${projectTitle}/`}> */}
          <ProjectThumb
            project={projectTitle}
            mp4={mp4}
            webm={webm}
            image={image}
            onClick={onClick}
            isHighlighted={isHighlighted}
          />
        </ExternalLink>
      </animated.div>
      <ProjectCaption isHighlighted={isHighlighted} isHover={isHover}>
        {caption}
      </ProjectCaption>
    </Container>
  );
};

ProjectCell.propTypes = {
  projectTitle: PropTypes.string,
  caption: PropTypes.string,
  mp4: PropTypes.string.isRequired,
  webm: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isHighlighted: PropTypes.bool,
};

ProjectCell.defaultProps = {
  projectTitle: 'no title set',
  caption: 'no caption set',
  onClick: () => {},
  isHighlighted: true,
};

export default ProjectCell;
